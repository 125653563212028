import { Component, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import PageLayout from "./components/PageLayout";
import RequireAuth from "./components/RequireAuth";
import ActionsPage from "./pages/ActionsPage";
import ActionSummaryPage from "./pages/ActionSummaryPage";
import BulletinPage from "./pages/Bulletin";
import DicesPage from "./pages/DicesPage";
import GoalsPage from "./pages/GoalsPage";
import GraveyardPage from "./pages/GraveyardPage";
import LorePage from "./pages/LorePage/LorePage";
import PolitiquePage from "./pages/PolitiquePage";
import TestPage from "./pages/TestPage";

//region Lazy load Pages
const LoginPage = lazy(() => import('./pages/LoginPage/LoginPage'));
const ResetPage = lazy(() => import('./pages/LoginPage/ResetPage'));
const LogoutPage = lazy(() => import('./pages/LogoutPage'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const HistoryPage = lazy(() => import('./pages/HistoryPage'));
const EventPage = lazy(() => import('./pages/EventPage'));
const EventsPage = lazy(() => import('./pages/EventsPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const MapPage = lazy(() => import('./pages/MapPage'));
const CharacterPage = lazy(() => import('./pages/Character/CharacterPage'));
const ItemsPage = lazy(() => import('./pages/ItemsPage'));
const FaqPage = lazy(() => import('./pages/FaqPage'));
const ScannerPage = lazy(() => import('./pages/ScannerPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const RulebookPage = lazy(() => import('./pages/Rulebook/RulebookPage'));
const MalkavianNetworkPage = lazy(() => import('./pages/MalkavianNetworkPage'));
const SchreckNetPage = lazy(() => import('./pages/SchreckNet/SchreckNetPage'));
const GatorCrossingGame = lazy(() => import('./pages/GatorCrossingPage/GatorCrossingPage'));
//endregion

export class MyRoutes extends Component {
  render() {
    return <Routes>
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/" element={<LoginPage />} />

      <Route path="/login" element={<LoginPage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/reset" element={<ResetPage />} />

      {/*<Route path="/test" element={<TestPage />} />*/}

      <Route element={<PageLayout />}>
        <Route
          path="cimetiere"
          element={
            <RequireAuth>
              <GraveyardPage />
            </RequireAuth>
          }
        />
        <Route path="dashboard">
          <Route
            index
            element={
              <RequireAuth>
                <DashboardPage />
              </RequireAuth>
            }
          />

          <Route path="evenements">
            <Route
              path=":slug"
              element={
                <RequireAuth>
                  <EventPage />
                </RequireAuth>
              }
            />
            <Route index element={<EventsPage />} />
          </Route>

          <Route path="personnage" errorElement={<ErrorBoundary />}>
            <Route
              index
              element={
                <RequireAuth>
                  <CharacterPage />
                </RequireAuth>
              }
              errorElement={<ErrorBoundary />}
            />

            <Route
              path=":slug"
              element={
                <RequireAuth admin>
                  <CharacterPage />
                </RequireAuth>
              }
              errorElement={<ErrorBoundary />}
            />
          </Route>

          <Route
            path="dices"
            element={
              <RequireAuth admin>
                <DicesPage />
              </RequireAuth>
            }
          />

          <Route path="actions">
            <Route
              path="new"
              element={
                <RequireAuth>
                  <ActionsPage />
                </RequireAuth>
              }
            />
            <Route
              index
              element={
                <RequireAuth>
                  <ActionSummaryPage />
                </RequireAuth>
              }
            />
          </Route>

          <Route
            path="historique"
            element={
              <RequireAuth>
                <HistoryPage />
              </RequireAuth>
            }
          />

          <Route path="carte">
            <Route
              index
              element={
                <RequireAuth>
                  <MapPage />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="politique">
            <Route
              index
              element={
                <RequireAuth>
                  <PolitiquePage />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="items">
            <Route
              index
              element={
                <RequireAuth>
                  <ItemsPage />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="profile">
            <Route
              index
              element={
                <RequireAuth>
                  <ProfilePage />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="bulletin">
            <Route
              index
              element={
                <RequireAuth>
                  <BulletinPage />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="objectifs">
            <Route
              index
              element={
                <RequireAuth>
                  <GoalsPage />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="madness">
            <Route
              index
              element={
                <RequireAuth>
                  <MalkavianNetworkPage />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="schrecknet">
            <Route
              index
              element={
                <RequireAuth>
                  <SchreckNetPage />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="gator" element={<GatorCrossingGame />} />
        </Route>
      </Route>

      <Route path="scan" element={<PageLayout />}>
        <Route
          index
          element={
            <RequireAuth>
              <ScannerPage />
            </RequireAuth>
          }
        />
      </Route>
      <Route path="faq" element={<PageLayout />}>
        <Route
          index
          element={
            <FaqPage />
          }
        />
      </Route>

      <Route path="test" element={<TestPage />} />
      <Route path="regles" element={<PageLayout />}>
        <Route
          index
          element={
            <RulebookPage />
          }
        />
      </Route>
      <Route path="histoire" element={<PageLayout />}>
        <Route index element={<LorePage />} />
        <Route path=":id" element={<LorePage />} />
      </Route>

    </Routes>
  }
}
