// @ts-nocheck
import { useCallback, useContext, useState } from 'react';
import { fetchApi } from '../../utils';
import { AppContext } from '../AppContext';
import {
  calculateSuccessCount,
  diceToLabel,
  diceToLabelNumeric,
  getAbilityComponent,
  modifierToCharacterValues,
} from './utils';

const useRollTheDice = (
  diceRef: React.RefObject<any>,
  diceEnabled = false,
  onRollSuccess = () => {
  },
) => {
  const appContext = useContext(AppContext);
  const [result, setResult] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [lastRoll, setLastRoll] = useState(null);
  const [isRolling, setIsRolling] = useState(false);

  // @ts-ignore
  const rollTheDice = useCallback(async (
    die: {
      id: '',
      attributes: {}
    },
    source: '',
  ) => {
    if (!diceEnabled || isRolling) return;

    const { dice, modifiers, difficulty, effects } = die.attributes;
    const freeSuccesses = 0;
    const character = appContext.currentCharacter;

    const attributesModifiersResult = modifiers.data
      .map(mod => modifierToCharacterValues(character, mod.attributes.name))
      .filter(i => i)
      .reduce((a, b) => a + b, 0);

    // before rolling
    getAbilityComponent(source);

    // rolling
    setIsRolling(true);
    const rollResult = await diceRef.current.roll(dice);
    const total = rollResult.map(r => r.value).reduce((a: number, b: number) => a + b, attributesModifiersResult);
    const count = calculateSuccessCount(total, difficulty, freeSuccesses);
    // apply the effect assi
    if (effects) {
      // TODO: BACKEND ROLL
      await fetchApi(`/characters/${character.id}/applyEffect`, {
        method: 'PUT',
        body: { data: { status_effects: { connect: effects.data.map(e => e.id) } } },
      });
      appContext.setAppliedEffects(effects.data);
    }

    // after rolls
    setResult(total);
    setSuccessCount(count);
    setLastRoll(die);
    onRollSuccess(
      diceToLabel(die),
      diceToLabelNumeric(die, rollResult[0].value, character), // todo: handle multiple dice
      total,
      count,
      difficulty,
    );

    setIsRolling(false);

    setTimeout(() => {
      diceRef.current.clear();
      setSuccessCount(0);
    }, 3000);
  }, [diceEnabled, isRolling, appContext.currentCharacter]);

  return {
    result,
    successCount,
    isRolling,
    rollTheDice,
    lastRoll,
  };
};

export default useRollTheDice;
