import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import MDEditor from '@uiw/react-md-editor';
import { Alert, Button, Card, Col, Divider, Form, notification, Rate, Row, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../components/AppContext";
import CREATE_ACTIONS from '../queries/create_actions.graphql';
import EVENT_ACTION from '../queries/getEventAction.graphql';
import GET_EVENT_MESSAGE from '../queries/getEventMessage.graphql';
import CREATE_RATING from '../queries/ratings.graphql';
import UPDATE_ACTIONS from '../queries/update_actions.graphql';
import UPDATE_RATING from '../queries/update_ratings.graphql';

const customIcons = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};

const now = dayjs().toISOString();

function countWords(str) {
  if (!str) return 0;
  const matches = str.match(/[\w\d\’\'-]+/gi);
  return matches ? matches.length : 0;
}

function ActionsPage() {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [event, setEvent] = useState(null);
  const [message, setMessage] = useState('');
  const [createAction, createActionData] = useMutation(CREATE_ACTIONS);
  const [updateAction, updateActionData] = useMutation(UPDATE_ACTIONS);
  const [createRating] = useMutation(CREATE_RATING);
  const [updateRating] = useMutation(UPDATE_RATING);
  const [getEventAction, { loading }] = useLazyQuery(EVENT_ACTION);
  const [getEventMessage] = useLazyQuery(GET_EVENT_MESSAGE);

  useEffect(() => {
    createActionData.error && notification.error({
      message: 'Erreur',
      description: createActionData.error.message
    });
  }, [createActionData.error]);

  useEffect(() => {
    if (context.user?.id) {
      getEventAction({ variables: { date: now, character: context.currentCharacter.id, user: context.user.id } })
        .then(({ data, error }) => {
          if (error) {
            notification.error({
              message: 'Erreur',
              description: error.message
            });
            navigate('/dashboard/actions', { replace: true });
            return;
          }
          if (data?.events) {
            const [event] = data.events.data;
            if (!event) {
              notification.error({
                message: 'Erreur',
                description: 'Aucun événement trouvé'
              });
              navigate('/dashboard/actions', { replace: true });
            } else {
              setEvent(event);
              const { actions, ratings, participants } = event.attributes;
              if (!participants.data.find(p => p.id === context.user.id)) {
                notification.error({
                  message: 'Erreur',
                  description: 'Vous n\'êtes pas inscrit à cet événement'
                });
                navigate('/dashboard/actions', { replace: true });
                return;
              }
              const isEditing = !!actions.data.length;
              if (isEditing) {
                form.setFieldsValue({
                  Moves: actions.data[0]?.attributes.Moves.map(({ description, result }) => ({
                    description,
                    result
                  })),
                  rating: ratings.data[0]?.attributes.rating,
                  message: ratings.data[0]?.attributes.message,
                  goules: actions.data[0]?.attributes.goules,
                  goules_result: actions.data[0]?.attributes.goules_result
                })
              }
            }
          }
        });

      getEventMessage({ variables: { event: event?.id } }).then(({ data }) => {
        setMessage(data?.characterEventMessages?.data[0]?.attributes?.message);
      });
    }
  }, [context, form, getEventAction, navigate]);

  async function onFinish(values) {
    try {
      let actionFunc = !!event.attributes.actions.data.length ? updateAction : createAction;
      let commentFunc = !!event.attributes.ratings.data.length ? updateRating : createRating;

      const actionId = event.attributes.actions.data[0]?.id;
      await actionFunc({
        variables: {
          id: actionId,
          input: {
            character: context.currentCharacter.id,
            event: event.id,
            publishedAt: dayjs().toISOString(),
            Moves: values.Moves,
            goules: values.goules
          }
        }
      });

      const ratingId = event.attributes.ratings.data[0]?.id;
      await commentFunc({
        variables: {
          id: ratingId,
          data: {
            event: event.id,
            message: values.message,
            publishedAt: dayjs().toISOString(),
            rating: values.rating,
            user: context.user.id,
          }
        }
      });

      notification.success({
        message: 'Succès',
        description: 'Actions soumise',
        duration: 3,
        onClose: () => navigate('/dashboard/actions', { replace: true })
      });

    } catch (e) {
      notification.error({ key: 'error', message: 'Erreur', description: e.message })
    }
  }

  if (loading) return <Spin spinning />

  return (
    <div className="content">
      {message &&
        <Alert message={message} type="info" showIcon />
      }
      <Card title={`${event?.attributes.name || ''} — Mes actions`}>
        <Typography.Text>
          Vous pouvez éditer vos actions tant qu'il n'y as pas de réponse de la part de votre narrateur.
        </Typography.Text>

        <Divider />
        <Form
          form={form}
          name="dynamic_form_item"
          layout="horizontal"
          initialValues={{
            Moves: [{ description: '', result: '' }, { description: '', result: '' }],
            rating: 4,
            message: '',
            goules: '',
          }}
          labelCol={{ span: 4 }}
          onFinish={onFinish}
          onFinishFailed={({ values, errorFields, outOfDate }) => {
            const errors = errorFields.map(({ errors, name }) => (
              <Row>
                <Col flex={1}>
                  <Typography.Text>
                    {name.join(':')}
                    {' => '}
                    {errors.join(', ')}
                  </Typography.Text>
                </Col>
              </Row>
            ));
            notification.error({
              key: 'error',
              message: 'Erreur',
              duration: 10,
              style: { width: 500 },
              description: (
                <>
                  <Typography.Text>
                    Vous devez remplir tout les champs
                  </Typography.Text>
                  <br />
                  <Row gutter={[0, 16]}>
                    <Col>{errors}</Col>
                  </Row>
                </>
              ),
            })
          }}
        >
          <Form.List name="Moves">
            {(fields, operation, meta) => (
              <>
                {fields.map((field, i) => {
                  let resultValue = form.getFieldValue(['Moves', field.name, 'result']);
                  let value = form.getFieldValue(['Moves', field.name, 'description']);
                  return (
                    <Form.Item
                      noStyle
                      key={field.key}
                      shouldUpdate={true}
                    >
                      <Form.Item
                        validateTrigger={['onChange', 'onBlur']}
                        labelCol={{
                          span: 24,
                          style: {
                            marginTop: 24
                          }
                        }}
                        label={`Action ${i + 1}`}
                        name={[field.name, 'description']}
                        validateStatus="error"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Votre action ne peut pas être vide'
                          },
                          {
                            message: 'Pas plus de 500 mots',
                            max: 500,
                            validator: (rule, value) => {
                              return countWords(value) <= 750 ? Promise.resolve() : Promise.reject(new Error('Trop de mots'));
                            }
                          }
                        ]}
                      >
                        <MDEditor
                          preview="edit"
                          textareaProps={{
                            placeholder: "Entrez votre action. 750 mots maximum.",
                            disabled: !!resultValue?.length,
                            style: {
                              border: '1px solid red !important'
                            }
                          }}
                        />
                      </Form.Item>
                    </Form.Item>
                  );
                })}
                <Form.ErrorList {...meta} />
              </>
            )}
          </Form.List>

          <Divider style={{ marginTop: 32 }}>
            Action de Goule(s)
            <br />
          </Divider>
          <br />
          <Form.Item
            name="goules"
            labelAlign="left"
            rules={[{ required: true }]}
          >
            <MDEditor preview="edit" />
          </Form.Item>

          <Divider style={{ marginTop: 32 }}>
            Évaluation de l'Événement
            <br />
          </Divider>

          <br />
          <Typography.Paragraph type="danger">
            🚨 ATTENTION 🚨
            Vous ne devez pas utiliser cette section pour vos actions.
            <br />
            Les actions doivent être dans la section "Actions de personnage".
            <br />
            Toutes explication sur vos action se retrouvant ici ne seront pas prise en compte.
            <br />
          </Typography.Paragraph>

          <Form.Item
            label="Note sur 5"
            name="rating"
            labelAlign="left"
            rules={[{ required: true }]}
          >
            <Rate character={({ index }) => customIcons[index + 1]} />
          </Form.Item>
          <Form.Item
            label="Commentaires"
            labelCol={{ span: 24 }}
            name="message"
            rules={[{ required: true, message: 'Entrez un commentaire' }]}
          >
            <MDEditor preview="edit" />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button type="primary" htmlType="submit" style={{ width: '100%', marginTop: 32 }}>
              Soumettre
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default ActionsPage;
