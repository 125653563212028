export const __DEV__ = process.env.NODE_ENV === 'development';

export const API_HOST = process.env.REACT_APP_API_HOST;

export async function fetchApi(
  path,
  options
) {
  try {
    const response = await fetch(`${API_HOST}/api${path}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(options.body),
      method: options.method,
    });
    console.log(response);
    return response.json();
  } catch (e) {
    console.error(e);
    return {};
  }
}

export const LocalStorageKeys = {
  LastMalkavianNetworkRead: 'lastMalkavianNetworkRead',
  LastNosferatuNetworkRead: 'lastNosferatuNetworkRead',
}
